<template>
  <v-card>
    <v-card-title class="primary--text">{{ title }}</v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-model="isFormValid"
        ref="form-edit-sucursal"
        form="form-edit-sucursal"
        id="form-edit-sucursal"
        @submit.prevent="saveEditSucursal()"
      >
        <v-row>
          <!-- Sucursal -->
          <v-col cols="12" md="12" class="py-0">
            <v-text-field
              label="Sucursal"
              dense
              outlined
              v-model="nombreSucursal"
              autocomplete="off"
              :rules="
                rules.required.concat([
                  rules.maxLength(nombreSucursal, 50),
                  rules.requiredTrim(nombreSucursal)
                ])
              "
            >
            </v-text-field>
          </v-col>
          <!-- Calle -->
          <v-col cols="12" md="8" sm="4" class="py-0">
            <v-text-field
              label="Calle"
              dense
              outlined
              autocomplete="off"
              v-model="calle"
              :rules="[rules.maxLength(calle, 50)]"
            >
            </v-text-field>
          </v-col>
          <!-- Número -->
          <v-col cols="12" md="4" sm="3" class="py-0">
            <v-text-field
              label="Número"
              dense
              autocomplete="off"
              outlined
              v-model="numeroCalle"
              :rules="[rules.maxLength(numeroCalle, 50)]"
            >
            </v-text-field>
          </v-col>
          <!-- Piso -->
          <v-col cols="12" md="3" sm="2" class="py-0">
            <v-text-field
              label="Piso"
              dense
              autocomplete="off"
              outlined
              v-model="piso"
              :rules="[rules.maxLength(piso, 10)]"
            >
            </v-text-field>
          </v-col>
          <!-- Departamento -->
          <v-col cols="12" md="3" sm="3" class="py-0">
            <v-text-field
              label="Dpto."
              dense
              autocomplete="off"
              outlined
              v-model="departamento"
              :rules="[rules.maxLength(departamento, 10)]"
            >
            </v-text-field>
          </v-col>
          <!-- Barrio -->
          <v-col cols="12" md="6" class="py-0">
            <v-autocomplete
              dense
              outlined
              v-model="barrioSelected"
              :items="barrios"
              item-value="id"
              autocomplete="off"
              item-text="value"
              clearable
              label="Barrio"
              :rules="rules.required"
            >
            </v-autocomplete>
          </v-col>
          <!-- Localidad -->
          <v-col cols="12" md="8" class="py-0">
            <v-autocomplete
              dense
              outlined
              v-model="localidadSelected"
              :items="localidades"
              item-value="id"
              autocomplete="off"
              item-text="value"
              clearable
              label="Localidad"
              :rules="rules.required"
            >
            </v-autocomplete>
          </v-col>
          <!-- Código Postal -->
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              label="Código postal"
              dense
              autocomplete="off"
              v-mask="'######'"
              outlined
              v-model="codPostal"
              :rules="[
               
                rules.maxNumber(codPostal, 32767)
              ]"
            >
            </v-text-field>
          </v-col>
          <!-- Teléfono -->
          <v-col cols="12" md="3" sm="6" class="py-0">
            <v-text-field
              label="Teléfono"
              dense
              autocomplete="off"
              outlined
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              v-model="telefono"
              :rules="[rules.maxLength(telefono, 50)]"
            >
            </v-text-field>
          </v-col>
          <!-- Celular -->
          <v-col cols="12" md="3" sm="6" class="py-0">
            <v-text-field
              label="Celular"
              dense
              autocomplete="off"
              outlined
              v-model="celular"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              :rules="[rules.maxLength(celular, 50)]"
            >
            </v-text-field>
          </v-col>
          <!-- Email -->
          <v-col cols="12" md="6" class="py-0">
            <v-text-field
              label="Email"
              dense
              autocomplete="off"
              outlined
              v-model="email"
              :rules="rules.email.concat([rules.maxLength(email, 100)])"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn outlined small @click="closeModal()"> Cancelar </v-btn>
      <v-btn
        small
        type="submit"
        :disabled="!isFormValid"
        form="form-edit-sucursal"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: {
    sucursal: { type: Object, required: false, default: null },
    empId: { type: Number, required: false }
  },
  data() {
    return {
      title: "Nueva sucursal",
      rules: rules,
      isFormValid: null,
      nombreSucursal: null,
      localidadSelected: null,
      localidades: [],
      barrioSelected: null,
      barrios: [],
      calle: null,
      numeroCalle: null,
      piso: null,
      departamento: null,
      codPostal: null,
      telefono: null,
      celular: null,
      email: null
    };
  },
  created() {
    this.setSelects();
    if (this.sucursal != null) {
      this.setEditForm();
    }
  },

  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getLocalidades: "afiliaciones/getLocalidades",
      getBarrios: "afiliaciones/getBarrios",
      saveSucursalEmpresa: "configuracion/saveSucursalEmpresa"
    }),
    setEditForm() {
      this.title = "Editar sucursal";
      this.nombreSucursal = this.sucursal.sucEmpNom;
      this.localidadSelected = this.sucursal.locId;
      this.barrioSelected = this.sucursal.barId;
      this.calle = this.sucursal.calle;
      this.numeroCalle = this.sucursal.numero;
      this.piso = this.sucursal.piso;
      this.departamento = this.sucursal.depto;
      this.codPostal = this.sucursal.codPostal;
      this.telefono = this.sucursal.telefono;
      this.celular = this.sucursal.celular;
      this.email = this.sucursal.email;
    },
    async setSelects() {
      this.localidades = await this.getLocalidades();
      this.barrios = await this.getBarrios();
    },
    async saveEditSucursal() {
      const sucursal = {
        sucEmpId: this.sucursal != null ? this.sucursal.sucEmpId : null,
        sucEmpNom: this.nombreSucursal,
        empId: this.empId,
        locId: this.localidadSelected,
        barId: this.barrioSelected,
        calle: this.calle,
        numero: this.numeroCalle,
        piso: this.piso,
        depto: this.departamento,
        codPostal: this.codPostal,
        telefono: this.telefono,
        celular: this.celular,
        email: this.email
      };
      const response = await this.saveSucursalEmpresa(sucursal);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("toggleModalEditSucursal");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
