<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="!showFilters ? (showFilters = true) : []"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Nombre/s -->
                  <v-col cols="12" sm="6" md="5" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      label="Empresa o razón social"
                      v-model.trim="nombreFilter"
                      autocomplete="off"
                      :rules="
                        nombreFilter !== null
                          ? [rules.maxLength(nombreFilter, 100)]
                          : []
                      "
                    ></v-text-field>
                  </v-col>

                  <!-- Número de CUIT -->
                  <v-col cols="12" sm="6" md="2" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      @change="
                        cuitFilter === null || cuitFilter === ''
                          ? []
                          : validarCUIL(cuitFilter)
                      "
                      label="CUIT"
                      v-model.trim="cuitFilter"
                      v-mask="'##-########-#'"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Rama sindical -->
                  <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      dense
                      outlined
                      v-model="ramaSelectedFilter"
                      :items="ramasSindicales"
                      item-value="id"
                      item-text="value"
                      clearable
                      label="Rama sindical"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    :disabled="!isFormValid"
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="empresas"
          class="elevation-1"
          :search="search"
          :loading="loadingEmpresas"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canAccessSucursales">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openSucursales(item)"
                >
                  {{ sucursalesIcon }}
                </v-icon>
              </template>
              <span>Ver sucursales</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar empresa</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteEmpresa(item.empId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar empresa</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-spacer></v-spacer>
        <v-tooltip left max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="to-right mt-4 py-4"
              v-bind="attrs"
              small
              outlined
              @click="exportExcelEmpresas"
            >
              Exportar detalle
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="50rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditEmpresa
        :empresa="this.empresa"
        @closeAndReload="closeAndReload"
        @applyFilters="applyFilters"
      ></EditEmpresa>
    </v-dialog>
    <v-dialog
      v-if="modalSucursales"
      v-model="modalSucursales"
      max-width="70rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <SucursalesEmpresa
        :empresa="empresa"
        @closeAndReload="closeAndReload"
      ></SucursalesEmpresa>
    </v-dialog>
    <DeleteDialog
      :maxWidth="'20rem'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import validateCUIL from "@/utils/helpers/validateCUIL";
import EditEmpresa from "@/components/modules/cuotas/configuracion/ConfiguracionDDJJAportes/EditEmpresa.vue";
import SucursalesEmpresa from "@/components/modules/cuotas/configuracion/ConfiguracionDDJJAportes/SucursalesEmpresa.vue";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    SucursalesEmpresa,
    GoBackBtn,
    FiltersSelected,
    EditEmpresa,
    Ayuda
  },
  name: "EmpresasYSucursales",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    sucursalesIcon: enums.icons.SUCURSAL,
    search: "",
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.EMPRESAS,
    showDeleteModal: false,
    showFilters: true,
    showFiltersSelected: false,
    filtersApplied: [],
    isFormValid: true,
    loadingEmpresas: false,
    idToDelete: null,
    sucursalEmpId: null,
    modalSucursales: false,
    routeToGo: "ConfiguracionDDJJAportes",
    title: enums.titles.EMPRESAS_Y_SUCURSALES,
    titleDelete: "¿Eliminar empresa?",
    rules: rules,
    empresas: [],
    sucursales: [],
    ramasSindicales: [],
    empresa: {},
    nombreFilter: null,
    ramaSelectedFilter: null,
    cuitFilter: null,
    idToEdit: null,
    headers: [
      {
        text: "Empresa",
        align: "start",
        sortable: false,
        value: "empNombre"
      },
      {
        text: "CUIT",
        sortable: false,
        value: "cuit",
        align: "center"
      },
      {
        text: "Dirección",
        sortable: false,
        value: "direccion"
      },
      {
        text: "Localidad",
        sortable: false,
        value: "localidadNom"
      },
      {
        text: "Teléfono",
        sortable: false,
        value: "telefono"
      },
      {
        text: "Rama sindical",
        sortable: false,
        value: "rama"
      },
      {
        text: "Acciones",
        sortable: false,
        value: "actions",
        align: "end"
      }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canAccessSucursales: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.setRamasSindicales();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchEmpresasAportantes: "configuracion/fetchEmpresasAportantes",
      getSucursalesByEmpresa: "afiliaciones/getSucursalesByEmpresa",
      deleteEmpresaAportante: "configuracion/deleteEmpresaAportante",
      getRamasSindicales: "afiliaciones/getRamasSindicales"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_EMPRESA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_EMPRESA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_EMPRESA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.ACCESO_SUCURSALES:
            this.canAccessSucursales = true;
            break;
          default:
            break;
        }
      });
    },
    async setRamasSindicales(rama) {
      this.ramasSindicales = await this.getRamasSindicales();
    },

    async applyFilters() {
      if (
        (this.nombreFilter == null || this.nombreFilter == "") &&
        (this.cuitFilter == null || this.cuitFilter == "") &&
        (this.ramaSelectedFilter == null || this.ramaSelectedFilter == "")
      ) {
        this.setAlert({
          type: "info",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      } else {
        this.closeOpenFilters();
        this.loadingEmpresas = true;
        this.customizeFiltersApplied();
        const data = {
          empNombre: this.nombreFilter,
          cuit: this.cuitFilter ? this.cuitFilter.replaceAll("-", "") : null,
          ramaId: this.ramaSelectedFilter ? this.ramaSelectedFilter.id : null
        };

        const response = await this.fetchEmpresasAportantes(data);
        this.empresas = response;
        this.loadingEmpresas = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.nombreFilter) {
        this.filtersApplied.splice(0, 1, {
          key: "nombreFilter",
          label: "Nombre",
          model: this.nombreFilter.toUpperCase()
        });
      }
      if (this.cuitFilter) {
        this.filtersApplied.splice(1, 1, {
          key: "cuitFilter",
          label: "CUIT",
          model: this.cuitFilter
        });
      }
      if (this.ramaSelectedFilter) {
        this.filtersApplied.splice(3, 1, {
          key: "ramaSelectedFilter",
          label: "Rama",
          model: this.ramaSelectedFilter.value
        });
      }
    },
    deleteEmpresa(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteEmpresaAportante({
        empId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    validarCUIL(cuil) {
      if (cuil != null) {
        const res = validateCUIL.validateCUITCUIL(cuil);
        if (res === false) {
          this.setAlert({
            type: "warning",
            message: "El CUIL ingresado es incorrecto."
          });
        }
      } else return;
    },
    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    openModal(empresa) {
      this.empresa = empresa;
      this.openModalEdit = true;
    },
    openSucursales(empresa) {
      this.empresa = empresa;
      this.modalSucursales = true;
    },
    exportExcelEmpresas() {
      let result = [];
      this.empresas.forEach(x =>
        result.push({
          ["Empresa"]: x.empNombre,
          ["CUIT"]: x.cuit,
          ["Dirección"]: x.direccion,
          ["Localidad"]: x.localidadNom,
          ["Teléfono"]: x.telefono,
          ["Email"]: x.email,
          ["Rama sindical"]: x.rama,
          ["Observaciones"]: x.observacion
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado de empresas"
      };
      helpersExcel.excelExport(formato, "Detalle de las empresas");
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.modalSucursales = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
