<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="sucEmpId"
            hide-default-footer
            :items="sucursales"
            class="elevation-1"
            no-data-text="La empresa no tiene sucursales"
            :loading="sucursalesLoading"
            loading-text="Cargando datos..."
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>

                  <v-col cols="12" md="2" class="pt-4 text-right">
                    <v-btn color="primary" @click="toggleModalEditSucursal()">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleModalEditSucursal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar sucursal </span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteSucursal(item.sucEmpId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar sucursal</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <!-- Modal edit sucursal -->
          <v-dialog
            v-if="modalEditSucursal"
            v-model="modalEditSucursal"
            max-width="45rem"
            @keydown.esc="toggleModalEditSucursal()"
            persistent
          >
            <EditSucursal
              :sucursal="sucursal"
              :empId="empresa.empId"
              @toggleModalEditSucursal="toggleModalEditSucursal"
            ></EditSucursal>
          </v-dialog>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn dense outlined @click="closeModal">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
      :maxWidth="'20rem'"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditSucursal from "../EditSucursal.vue"
export default {
  components: { DeleteDialog, EditSucursal },
  name: "SucursalesEmpresa",
  props: {
    empresa: { type: Object, required: false, default: null }
  },
  data() {
    return {
      title: `Sucursales de la empresa: ${this.empresa.empNombre.toUpperCase()}`,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      rules: rules,
      sucursalesLoading: false,
      sucursales: [],
      sucursal: {},
      titleDelete: "¿Eliminar sucursal?",
      showDeleteModal: false,
      sucursalCreada: false,
      idToDelete: null,
      modalEditSucursal: false,
      loading: false,
      headers: [
        {
          text: "Sucursal",
          align: "start",
          sortable: false,
          value: "sucEmpNom"
        },
        {
          text: "Localidad",
          sortable: false,
          value: "locNom"
        },
        {
          text: "Barrio",
          sortable: false,
          value: "barNom"
        },
        {
          text: "Dirección",
          sortable: false,
          value: "direccion"
        },

        { text: "Acciones", value: "actions", sortable: false, align: "center" }
      ]
    };
  },
  created() {
    this.getSucursalesByEmpresa();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getSucursalesEmpresa: "configuracion/getSucursalesEmpresa",
      deleteSucursalEmpresa: "configuracion/deleteSucursalEmpresa"
    }),

    async getSucursalesByEmpresa() {
      this.sucursalesLoading = true;
      const response = await this.getSucursalesEmpresa({
        empId: this.empresa.empId
      });
      this.sucursales = response;
      this.sucursalesLoading = false;
    },
    deleteSucursal(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteSucursalEmpresa({
        sucEmpId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.getSucursalesByEmpresa();
      }
    },
    async setSucursales() {
      this.loading = true;
      //   const data = await this.getRelacionesGrupoFamiliar(this.agectaId);
      //   this.sucursales = data;
      this.loading = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },

    toggleModalEditSucursal(item) {
      this.sucursal = item;
      this.getSucursalesByEmpresa();
      this.modalEditSucursal = !this.modalEditSucursal;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
